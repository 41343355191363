import React, { useState } from "react";
import data from "./chordProgressions.json";
import "../../App.css";
import { useSelector } from "react-redux";
import {
  selectScaleNote,
  selectChords,
  selectAeolien,
} from "./MajourScaleSlice";
//import musicScalePic from "../../ZmusicScale.png";

//AS a User I want to see notes for the chords on hoover
//Watch and build with Tutorial outube.com/watch?v=QGVXmoZWZuw

function ChordProgressions(props) {
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  const note = useSelector(selectScaleNote);
  const chordsM = useSelector(selectChords);

  const aeolian = useSelector(selectAeolien);

  const chords = props.aeolian ? chordsM : aeolian;

  // const chord = useSelector(selectChordI);
  // Can I compare the Roman numbers that I am maping over
  // with the value of the state roman number?
  // So that the roman number I am displaying are
  //equal to the current state Roman numbers

  const chordsDisplayed = data.map((x, index) => (
    //Can I do a map() here to add the state of the Roman numbers?
    <div key={index} className="margin-top">
      <div className="flexContainer ">
        {x.map((y, ind) => {
          if (y === "I") {
            return (
              <div className="card" key={ind}>
                <div
                  className={
                    isActive
                      ? "card_inner card_face--front"
                      : "card_inner  card_face--back"
                  }
                  onClick={toggleClass}
                >
                  <div className="card_face card_face--front">
                    <h2>{y}</h2>
                  </div>

                  <div className="card_face card_face--back">
                    <div className="card_content">
                      <div className="card_header">
                        <h2>{chords[0]}</h2>
                      </div>
                      <div className="card_body">
                        <p>
                          {" "}
                          <span className="margin-left">{note[0]} - </span>
                          <span className="margin-left">{note[2]} - </span>
                          <span className="margin-left">{note[4]} </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          if (y === "II") {
            return (
              <div className="card">
                <div
                  className={
                    isActive
                      ? "card_inner card_face--front"
                      : "card_inner  card_face--back"
                  }
                  onClick={toggleClass}
                >
                  <div className="card_face card_face--front">
                    <h2>{y}</h2>
                  </div>

                  <div className="card_face card_face--back">
                    <div className="card_content">
                      <div className="card_header">
                        <h2>{chords[1]}</h2>
                      </div>
                      <div className="card_body">
                        <p>
                          <span className="margin-left">{note[1]} - </span>
                          <span className="margin-left">{note[3]} - </span>
                          <span className="margin-left">{note[5]} </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }

          if (y === "III") {
            return (
              <div className="card">
                <div
                  className={
                    isActive
                      ? "card_inner card_face--front"
                      : "card_inner  card_face--back"
                  }
                  onClick={toggleClass}
                >
                  <div className="card_face card_face--front">
                    <h2>{y}</h2>
                  </div>

                  <div className="card_face card_face--back">
                    <div className="card_content">
                      <div className="card_header">
                        <h2>{chords[2]}</h2>
                      </div>
                      <div className="card_body">
                        <p>
                          <span className="margin-left">{note[2]} - </span>
                          <span className="margin-left">{note[4]} - </span>
                          <span className="margin-left">{note[6]} </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }

          if (y === "IV") {
            return (
              <div className="card">
                <div
                  className={
                    isActive
                      ? "card_inner card_face--front"
                      : "card_inner  card_face--back"
                  }
                  onClick={toggleClass}
                >
                  <div className="card_face card_face--front">
                    <h2>{y}</h2>
                  </div>

                  <div className="card_face card_face--back">
                    <div className="card_content">
                      <div className="card_header">
                        <h2>{chords[3]}</h2>
                      </div>
                      <div className="card_body">
                        <p>
                          <span className="margin-left">{note[3]} - </span>
                          <span className="margin-left">{note[5]} - </span>
                          <span className="margin-left">{note[1]} </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }

          if (y === "V") {
            return (
              <div className="card">
                <div
                  className={
                    isActive
                      ? "card_inner card_face--front"
                      : "card_inner  card_face--back"
                  }
                  onClick={toggleClass}
                >
                  <div className="card_face card_face--front">
                    <h2>{y}</h2>
                  </div>

                  <div className="card_face card_face--back">
                    <div className="card_content">
                      <div className="card_header">
                        <h2>{chords[4]}</h2>
                      </div>
                      <div className="card_body">
                        <p>
                          <span className="margin-left">{note[4]} - </span>
                          <span className="margin-left">{note[6]} - </span>
                          <span className="margin-left">{note[0]} </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }

          if (y === "VI") {
            return (
              <div className="card">
                <div
                  className={
                    isActive
                      ? "card_inner card_face--front"
                      : "card_inner  card_face--back"
                  }
                  onClick={toggleClass}
                >
                  <div className="card_face card_face--front">
                    <h2>{y}</h2>
                  </div>

                  <div className="card_face card_face--back">
                    <div className="card_content">
                      <div className="card_header">
                        <h2>{chords[5]}</h2>
                      </div>
                      <div className="card_body">
                        <p>
                          <span className="margin-left">{note[5]} - </span>
                          <span className="margin-left">{note[1]} - </span>
                          <span className="margin-left">{note[3]} </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          if (y === "VII") {
            return (
              <div className="card">
                <div
                  className={
                    isActive
                      ? "card_inner card_face--front"
                      : "card_inner  card_face--back"
                  }
                  onClick={toggleClass}
                >
                  <div className="card_face card_face--front">
                    <h2>{y}</h2>
                  </div>

                  <div className="card_face card_face--back">
                    <div className="card_content">
                      <div className="card_header">
                        <h2>{chords[6]}</h2>
                      </div>
                      <div className="card_body">
                        <p>
                          <span className="margin-left">{note[6]} - </span>
                          <span className="margin-left">{note[2]} - </span>
                          <span className="margin-left">{note[4]} </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return <div className="nothing">nothing!!</div>;
        })}
      </div>
    </div>
  ));

  /* const test = () => {
    switch (y) {
      case "I":
        chords[0] + "      , ";

      case "II":
        chords[1] + "      , ";

      case "III":
        chords[2] + "      , ";

      case "IV":
        chords[3] + " , ";
      case "V":
        chords[4] + " , ";
      case "VI":
        chords[5] + " , ";
      case "VII":
        chords[6] + " , ";
      default:
        chords[0] + " , ";
    }
  };
 */

  return (
    <div>
      <h2>Chord proggressions in {note[[0]]}</h2>

      {chordsDisplayed}
      <div className="flexContainer ">
        <div className="card">
          <div
            className={
              isActive
                ? "card_inner card_face--front"
                : "card_inner  card_face--back"
            }
            onClick={toggleClass}
          >
            <div className="card_face card_face--front">
              <h2>I</h2>
            </div>

            <div className="card_face card_face--back roman">
              <div className="card_content">
                <div className="card_header">
                  <h4>
                    Change to I,II,III,
                    <br></br>IV,V,VI,VII
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChordProgressions;
