import { useSelector, useDispatch } from "react-redux";
import {
  changeScaleC,
  changeScaleD,
  changeScaleE,
  changeScaleEb,
  selectScale,
  selectScaleNote,
} from "./MajourScaleSlice";
import data from "./scales.json";
import "../../App.css";

const romanNumbers = data.Roman;

const degree = romanNumbers.map((x, i) => {
  return (
    <div className="noteContainer" key={i}>
      {x}
    </div>
  );
});

function MajourScale(props) {
  const notes = useSelector(selectScale);
  const note = useSelector(selectScaleNote);

  const dispatch = useDispatch();

  return (
    <div>
      <h2>The notes in {note[0]} are</h2>

      <div className="flexContainer">
        <div className="noteContainer" key="0">
          {notes[0]}
        </div>
        <div className="noteContainer" key="1">
          {notes[1]}
        </div>
        <div className="noteContainer" key="2">
          {notes[2]}
        </div>
        <div className="noteContainer" key="3">
          {notes[3]}
        </div>
        <div className="noteContainer" key="4">
          {notes[4]}
        </div>
        <div className="noteContainer" key="5">
          {notes[5]}
        </div>
        <div className="noteContainer" key="6">
          {notes[6]}
        </div>
      </div>

      <span className="flexContainer">{degree}</span>

      <h3> Change Key</h3>
      <div className="flexContainer">
        {" "}
        <div
          className="button"
          aria-label="Changing scale"
          onClick={() => dispatch(changeScaleC())}
        >
          C
        </div>
        <div
          className="button"
          aria-label="Changing scale"
          onClick={() => dispatch(changeScaleD())}
        >
          D
        </div>
        <div
          className="button"
          aria-label="Changing scale"
          onClick={() => dispatch(changeScaleE())}
        >
          E
        </div>
        <div
          className="button"
          aria-label="Changing scale"
          onClick={() => dispatch(changeScaleEb())}
        >
          Eb
        </div>
      </div>
    </div>
  );
}

export default MajourScale;
