import React from "react";
//import logo from "./logo.svg";
//import { Counter } from "./features/counter/Counter";
import "./App.css";
//import { Scale } from "./features/counter/Scale";
import MajourScale from "./features/counter/MajourScale";

//import musicScalePic from "./ZmusicScale.png";

import RandomScale from "./RandomScale";

//import Pentatonic from "./features/counter/Pentatonic";
//import Dorian from "./features/counter/Dorian";
import ChordProgressions from "./features/counter/ChordProgressions";
import Hero from "./features/counter/Hero";
import Aeolian from "./features/counter/Aeolian";

/* "c" : {"Majour":["C", "D", "E", "F", "G", "A", "B", "C"],
"Pentatonic":["C", "D", "E", "G", "A"]},

 "d": {"Majour":["d ", "e ", "f# ", " a ", "b "]} */

//const keySignitureC = ["C ", "D ", "E ", "F ", "G ", "A ", "B "];
//const keySignitureD = ["D ", "E ", "F# ", "G ", "A ", "B ", "C#", "D"];

//const keySigniture = keySignitureC;
//With State Change the keySigniture to const keySignitureD

//const tonic = keySigniture[0];
//const dominant = keySigniture[4];
//const subdominant = keySigniture[3];
//   <h3>{musicalKey.notes}</h3>
//

function App() {
  return (
    <div className="App">
      <Hero />
      <MajourScale />
      <Aeolian />
      <RandomScale />

      <ChordProgressions />
      <ChordProgressions scale="aeolian" />

      <h2>I = tonic, IV = subdominant, V = dominant</h2>

      <div className="flexContainer">
        <button>Change the Key</button>
        <button>Cadencess</button>
        <button>Types of Scales</button>
      </div>
    </div>
  );
}

export default App;
