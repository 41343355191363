import React from "react";
import "../../App.css";

function Hero(props) {
  return (
    <div className="card_face--front hero">
      <i>
        {" "}
        <h1 style={{ color: "white" }}>Music Magic</h1>
        <p>Tools for Composing and Improvising</p>
      </i>
    </div>
  );
}

export default Hero;
