import { createSlice } from "@reduxjs/toolkit";
import data from "./scales.json";

const notes = data.C.Majour;
const chords = data.C.Chords;
const pentatonic = data.C.pentatonic;
const chordI = data.C.I;
const chordII = data.C.II;
const chordIII = data.C.III;
const chordIV = data.C.IV;
const chordV = data.C.V;
const chordVI = data.C.VI;
const chordVII = data.C.VII;
const aeolian = data.C.Aeolian;

//console.log(data);
/* const pentatonic1 = notes.slice(0, 3);
const pentatonic2 = notes.slice(4, 6);
const pentatonic = pentatonic1 + pentatonic2; */

//const newNotes = ["d ", "e ", "f# ", "g", " a ", "b ", "c#"];
const initialState = {
  notes,
  chords,
  pentatonic,
  chordI,
  chordII,
  chordIII,
  chordIV,
  chordV,
  chordVI,
  chordVII,
  aeolian,
  // newNotes,
};
export const majorScaleSlice = createSlice({
  name: "music",
  initialState,
  reducers: {
    changeScaleC: (state) => {
      state.notes = data.C.Majour;
      state.pentatonic = data.C.pentatonic;
      state.chords = data.C.Chords;
      state.aeolian = data.C.aeolian;
    },
    changeScaleD: (state) => {
      state.notes = data.D.Majour;
      state.pentatonic = data.D.pentatonic;
      state.chordI = data.D.I;
      state.chords = data.D.Chords;
      state.aeolian = data.D.aeolian;
    },
    changeScaleE: (state) => {
      state.notes = data.E.Majour;
      state.pentatonic = data.E.pentatonic;
      state.chords = data.E.Chords;
    },
    changeScaleEb: (state) => {
      state.notes = data.Eb.Majour;
      state.chords = data.Eb.Chords;
    },
  },
});

export const { changeScaleC, changeScaleD, changeScaleE, changeScaleEb } =
  majorScaleSlice.actions;

///
export const selectScale = (state) => state.music.notes;
export const selectScaleNote = (state) => state.music.notes;
export const selectChords = (state) => state.music.chords;
export const selectScalePentatonic = (state) => state.music.pentatonic;
export const selectChordI = (state) => state.music.chordI;
export const selectChordII = (state) => state.music.chordII;
export const selectChordIII = (state) => state.music.chordIII;
export const selectChordIV = (state) => state.music.chordIV;
export const selectChordV = (state) => state.music.chordV;
export const selectAeolien = (state) => state.music.aeolian;
export default majorScaleSlice.reducer;
