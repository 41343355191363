import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import musicReduser from "../features/counter/MajourScaleSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    music: musicReduser,
  },
});
