import React from "react";
import { useSelector } from "react-redux";
import { selectScale } from "./features/counter/MajourScaleSlice";

function RandomScale(props) {
  const notes = useSelector(selectScale);
  const note = () => {
    const Random = Math.random();
    const Random7 = Random * 7;

    const Random7Even = Math.floor(Random7);
    return notes[Random7Even];
  };
  return (
    <div>
      <h2>Random notes in {notes[0]}</h2>

      <div className="flexContainer">
        <div className="noteContainer">{note()} </div>
        <div className="noteContainer">{note()} </div>
        <div className="noteContainer">{note()} </div>
        <div className="noteContainer">{note()} </div>
        <div className="noteContainer">{note()} </div>
        <div className="noteContainer">{note()} </div>
        <div className="noteContainer">{note()} </div>
      </div>
    </div>
  );
}

export default RandomScale;
